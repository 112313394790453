/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useEmptyStringFormat } from '@/hooks/fn';
import hyRequest from '@/service';
import { useStore } from '@/store';
export default defineComponent({
  components: {},
  name: 'FixedInfo',
  props: {
    deviceInfo: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var store = useStore();
    var emptyStringFormat = useEmptyStringFormat();
    var router = useRouter();
    var toDarwings = function toDarwings() {
      store.commit('production/updateDrawList', props.deviceInfo.draw);
      router.push({
        name: 'Drawings'
      });
    };
    var toUsings = function toUsings() {
      store.commit('production/updateUseList', props.deviceInfo.use);
      router.push({
        name: 'Usings'
      });
    };
    var tmpShow = ref(false);
    onMounted(function () {
      hyRequest.post({
        url: '/deviceapp/deviceTempDetail',
        data: {
          deviceId: props.id
        }
      }).then(function (data) {
        tmpShow.value = data;
      });
    });
    return {
      emptyStringFormat: emptyStringFormat,
      toDarwings: toDarwings,
      toUsings: toUsings,
      tmpShow: tmpShow
    };
  }
});