/* unplugin-vue-components disabled */import _objectSpread from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import OpenCloseItem from './OpenCloseItem.vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useMainData from '../hooks/useMainData';
import useSandData from '../hooks/useSandData';
import useHourData from '../hooks/useHourData';
import usePermission from '../hooks/usePermission';
import { BarEchart } from '@/components/page-echarts';
import YieldSum from './YieldSum.vue';
import localCache from '@/utils/localCache';
import useDeviceAreaList from '../hooks/useDeviceAreaList';
import CNoData from '@/components/CNoData.vue';
import hyRequest from '@/service';
// import { CellGroup } from 'vant';
// import { ISandListItem } from '@/service/production/proReportAggregate/type';
export default defineComponent({
  name: 'NaturalDay',
  components: {
    OpenCloseItem: OpenCloseItem,
    BarEchart: BarEchart,
    YieldSum: YieldSum,
    CNoData: CNoData
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    //当前日期区间
    var date = ref({
      startTime: dayjs().format('YYYY/MM/DD') + ' 00:00:00',
      endTime: dayjs().format('YYYY/MM/DD') + ' 23:59:59'
    });
    var confrimDate = function confrimDate(value) {
      console.log('confirmDate');
      date.value = {
        startTime: formatDate(value) + ' 00:00:00',
        endTime: formatDate(value) + ' 23:59:59'
      };
      // 获取主机汇总数据
      getMainDataAsync();
      // 获取制砂总汇
      getSandDataAsyc();
      sandMakingList.value = [];
      getDeviceAreaListAsync();
      getProductHourDataAsync();
      getWaterData(date);
    };
    // 获取主破总汇
    var _useMainData = useMainData(date),
      _useMainData2 = _slicedToArray(_useMainData, 2),
      mainData = _useMainData2[0],
      getMainDataAsync = _useMainData2[1];
    var mainDataTotal = computed(function () {
      var tmp = dayjs(date.value.startTime).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD');
      return {
        num: mainData.value.pro_num,
        // desc: mainData.value.material_name
        desc: tmp ? '产量/吨 (00:00 ~ 目前)' : '产量/吨 (00:00 ~ 24:00)',
        status: mainData.value.status
      };
    });
    var _usePermission = usePermission(),
      _usePermission2 = _slicedToArray(_usePermission, 2),
      hasPermission = _usePermission2[0],
      canUpdateProDataAsync = _usePermission2[1];
    // 获取制砂总汇和制砂各分类总汇列表
    var _useSandData = useSandData(date),
      _useSandData2 = _slicedToArray(_useSandData, 4),
      sandData = _useSandData2[0],
      sandListData = _useSandData2[1],
      sandEle = _useSandData2[2],
      getSandDataAsyc = _useSandData2[3];
    var sandDataTotal = computed(function () {
      var tmp = dayjs(date.value.startTime).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD');
      return {
        num: sandData.value.pro_num,
        // desc: sandData.value.material_name,
        desc: tmp ? '产量/吨 (00:00 ~ 目前)' : '产量/吨 (00:00 ~ 24:00)',
        status: sandData.value.status
      };
    });
    var waterDataTotal = computed(function () {
      var tmp = dayjs(date.value.startTime).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD');
      return {
        num: Number(waterData.value.washedSand.pro_num),
        desc: tmp ? '产量/吨 (00:00 ~ 目前)' : '产量/吨 (00:00 ~ 24:00)',
        status: waterData.value.status + ''
      };
    });
    var sandEleTotal = computed(function () {
      return [{
        num: sandEle.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: sandEle.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    var waterSandEleTotal = computed(function () {
      return [{
        num: sandEle.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: sandEle.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    var waterTotal = computed(function () {
      return [{
        num: waterData.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: waterData.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    // 控制停机详情列表弹窗显示隐藏show，查看停机方法，查询时间范围内停机详情
    var router = useRouter();
    var toCheckDevice = function toCheckDevice() {
      router.push({
        name: 'ProEquipmentLive'
      });
    };
    var _useDeviceAreaList = useDeviceAreaList(date),
      _useDeviceAreaList2 = _slicedToArray(_useDeviceAreaList, 3),
      mainBreakingList = _useDeviceAreaList2[0],
      sandMakingList = _useDeviceAreaList2[1],
      getDeviceAreaListAsync = _useDeviceAreaList2[2];
    var params = computed(function () {
      return {
        dayTime: dayjs(new Date(date.value.startTime)).format('YYYY-MM-DD')
      };
    });
    var _useHourData = useHourData(params),
      _useHourData2 = _slicedToArray(_useHourData, 2),
      hourDataTotal = _useHourData2[0],
      getProductHourDataAsync = _useHourData2[1];
    console.log(hourDataTotal);
    var getWaterData = function getWaterData(data) {
      hyRequest.post({
        url: '/product/getWashedSandData',
        data: _objectSpread(_objectSpread({}, data.value), {}, {
          areaId: '121',
          formulaType: 'wash_line'
        })
      }).then(function (data) {
        waterData.value = data;
      });
    };
    var waterData = ref({
      status: '1',
      washedSand: {
        pro_num: '0',
        material_name: ''
      },
      totalEle: '0',
      tonEle: '0'
    });
    var factoryID = ref(0);
    onMounted(function () {
      hyRequest.post({
        url: '/factoryapp/getFactoryInfo'
      }).then(function (data) {
        console.log(data.factory);
        factoryID.value = data.factory;
      });
      // console.log('onMounted')
      getDeviceAreaListAsync();
      // 获取主机汇总数据
      getMainDataAsync();
      // 获取制砂总汇
      getSandDataAsyc();
      getProductHourDataAsync();
      canUpdateProDataAsync();
      getWaterData(date);
    });
    var switchClick = function switchClick() {
      window.location.href = "/table/sandMachineHour.html?factoryId=".concat(localCache.getItem('factoryId'));
    };
    return {
      confrimDate: confrimDate,
      mainData: mainData,
      mainDataTotal: mainDataTotal,
      sandData: sandData,
      sandListData: sandListData,
      mainBreakingList: mainBreakingList,
      sandMakingList: sandMakingList,
      emptyStringFormat: emptyStringFormat,
      toCheckDevice: toCheckDevice,
      hourDataTotal: hourDataTotal,
      sandDataTotal: sandDataTotal,
      waterSandEleTotal: waterSandEleTotal,
      date: date,
      hasPermission: hasPermission,
      switchClick: switchClick,
      sandEleTotal: sandEleTotal,
      waterData: waterData,
      waterTotal: waterTotal,
      waterDataTotal: waterDataTotal,
      factoryID: factoryID
    };
  }
});