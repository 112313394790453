/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import localCache from '@/utils/localCache';
import DeviceDetail from './cpns/DeviceDetail.vue';
import { showToast } from 'vant';
import { useStore } from '@/store';
export default defineComponent({
  name: 'ProEquipmentLiveDetail',
  components: {
    DeviceDetail: DeviceDetail
  },
  setup: function setup() {
    var _route$params;
    var store = useStore();
    var route = useRoute();
    var curDevice = ref({
      deviceName: '',
      deviceId: '',
      index: 0
    });
    if ((_route$params = route.params) !== null && _route$params !== void 0 && _route$params.deviceName) {
      curDevice.value.deviceName = route.params.deviceName;
      curDevice.value.deviceId = route.params.deviceId;
      curDevice.value.index = Number(route.params.index);
      localCache.setItem('deviceName', route.params.deviceName);
      localCache.setItem('deviceId', route.params.deviceId);
      localCache.setItem('deviceIndex', route.params.index);
    } else {
      curDevice.value.deviceName = localCache.getItem('deviceName');
      curDevice.value.deviceId = localCache.getItem('deviceId');
      curDevice.value.index = Number(localCache.getItem('deviceIndex'));
    }
    //获取当前设备所属的设备列表
    var list = computed(function () {
      return store.getters['production/deviceGroupList'];
    });
    var preClick = function preClick() {
      var _curDevice$value;
      if (((_curDevice$value = curDevice.value) === null || _curDevice$value === void 0 ? void 0 : _curDevice$value.index) === 0) {
        showToast('当前已经是第一条了');
        return;
      }
      if (curDevice.value) {
        --curDevice.value.index;
        curDevice.value.deviceName = list.value[curDevice.value.index].device;
        curDevice.value.deviceId = list.value[curDevice.value.index].deviceId;
      }
    };
    var nextClick = function nextClick() {
      var _curDevice$value2;
      if (((_curDevice$value2 = curDevice.value) === null || _curDevice$value2 === void 0 ? void 0 : _curDevice$value2.index) === list.value.length - 1) {
        showToast('没有下一条了');
        return;
      }
      if (curDevice.value) {
        console.log(curDevice.value);
        ++curDevice.value.index;
        curDevice.value.deviceName = list.value[curDevice.value.index].device;
        curDevice.value.deviceId = list.value[curDevice.value.index].deviceId;
      }
    };
    return {
      curDevice: curDevice,
      preClick: preClick,
      nextClick: nextClick
    };
  }
});