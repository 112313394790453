import hyRequest from '@/service';
import {
  IProductData,
  IProductDataParams,
  IProductUpdateLog,
  IUpdateParamsItem
} from './type';

enum DashboardApi {
  findProductDayData = '/product/findProductDayData',
  updateProductData = '/product/updateProductData',
  findProductUpdateLog = '/product/findProductUpdateLog'
}

//查询某天当前主机/制砂及各皮带秤产量
export function findProductDayData(data: IProductDataParams) {
  return hyRequest.post<IProductData>({
    url: DashboardApi.findProductDayData,
    data
  });
}
//修改生产产量
export function updateProductData(data: IUpdateParamsItem[]) {
  return hyRequest.post<string>({
    url: DashboardApi.updateProductData,
    data
  });
}
//查询皮带秤修改记录
export function findProductUpdateLog(data: IProductDataParams) {
  return hyRequest.post<IProductUpdateLog>({
    url: DashboardApi.findProductUpdateLog,
    data
  });
}
