import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e58e4d26"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "modifyRecords"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  class: "list-item-content"
};
var _hoisted_5 = {
  class: "flex align-center justify-between"
};
var _hoisted_6 = {
  class: "blue"
};
var _hoisted_7 = {
  class: "flex align-center justify-between"
};
var _hoisted_8 = {
  class: "info"
};
var _hoisted_9 = {
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_date_select = _resolveComponent("c-date-select");
  var _component_c_no_data = _resolveComponent("c-no-data");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_date_select, {
    class: "mb-4",
    onConfirmDate: _ctx.confirmDate
  }, null, 8, ["onConfirmDate"]), _ctx.productUpdateLog.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.productUpdateLog, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "box"
    }, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.show_name), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, "原：" + _toDisplayString(item.old_net_weight), 1), _createElementVNode("div", null, [_createTextVNode(" 改为： "), _createElementVNode("span", _hoisted_6, _toDisplayString(item.new_net_weight), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, "修改人：" + _toDisplayString(item.user_name), 1), _createElementVNode("span", _hoisted_9, "修改时间：" + _toDisplayString(item.create_time), 1)])])]);
  }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  }))])]);
}