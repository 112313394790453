/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { useEmptyStringFormat } from '@/hooks/fn';
import useProductUpdateLog from './hooks/useProductUpdateLog';
export default defineComponent({
  name: 'ModifyRecords',
  components: {},
  setup: function setup() {
    // 产量修正历史记录页面是一个单独的页面，从产量修正跳转过来并不需要给此页面传默认日期
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var emptyStringFormat = useEmptyStringFormat();
    var params = ref({
      dayTime: dayjs().format('YYYY/MM/DD')
    });
    var confirmDate = function confirmDate(value) {
      params.value.dayTime = formatDate(value);
      findProductUpdateLogAsync();
    };
    var _useProductUpdateLog = useProductUpdateLog(params),
      _useProductUpdateLog2 = _slicedToArray(_useProductUpdateLog, 2),
      productUpdateLog = _useProductUpdateLog2[0],
      findProductUpdateLogAsync = _useProductUpdateLog2[1];
    onMounted(function () {
      findProductUpdateLogAsync();
    });
    return {
      confirmDate: confirmDate,
      emptyStringFormat: emptyStringFormat,
      productUpdateLog: productUpdateLog
    };
  }
});