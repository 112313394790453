import HyRequest from '@/service';
// 添加巡保任务
// taskType 0巡检，1巡保（默认0）
// dateSource数据来源 1-巡保计划、2-APP录入
// deviceId设备主键id
export function postAddPatrolGuard(params: {
  inspectionResult?: number;
  deviceId: number;
  taskType?: number;
  dateSource: number;
  inspectionItemInfo?: any;
  inspectionContent?: any;
  inspectionHistoryAttachmentIdList?: number[];
  inspectionItemId: any;
}): Promise<any> {
  return HyRequest.post({
    url: '/h5/inspectionHistory/add',
    data: {
      ...params
    }
  });
}
// 删除任务
export function delPatrolGuard(id: number): Promise<any> {
  return HyRequest.delete({
    url: `/h5/eaInspectionTask/deleteTask/${id}`
  });
}
// 取消
export function cancelPatrolGuard(data: {
  id: number;
  status: number;
}): Promise<any> {
  return HyRequest.post({
    url: `/h5/eaInspectionTask/cancelTask`,
    data
  });
}
// 上传图片
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function uploadImage(data: any, callback?: any) {
  return HyRequest.post({
    // url: `/h5/inspectionHistory/upload`,
    url: `/h5/inspectionHistory/uploadFileList`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (event) => {
      if (event.lengthComputable) {
        const progressPercent = (event.loaded / event.total) * 100;
        callback && callback(progressPercent);
      }
    }
  });
}
// 删除图片
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function delImage(id: any) {
  return HyRequest.delete({
    url: `/h5/inspectionHistory/delAttach?id=${id}`
  });
}
// 获取巡保列表
export function getPatrolGuard(data: {
  pageIndex: number;
  pageSize: number;
  t?: {
    taskType?: number;
    inspectionTaskCode: number;
    taskName: string;
    deviceModel: string;
    deviceName: string;
    status: number;
  };
}): Promise<any> {
  return HyRequest.post({
    url: `/h5/eaInspectionTask/selectTaskByPage`,
    data
  });
}
// 获取巡保任务详情
export function getPatrolGuardDetail(data: unknown): Promise<any> {
  return HyRequest.get({
    url: `/h5/eaInspectionTask/getTaskInfo`,
    params: data
  });
}
