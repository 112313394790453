import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-26daaf63"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "shutdown-reason"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "title flex align-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_downtime_detail_item = _resolveComponent("downtime-detail-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.title) + " ", 1), _createElementVNode("span", {
    class: "back ml-auto",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.back && _ctx.back.apply(_ctx, arguments);
    })
  }, "返回")]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proCloseList, function (item, index) {
    return _openBlock(), _createBlock(_component_downtime_detail_item, {
      key: index,
      item: item,
      onUpdate: _ctx.updateProCloseList
    }, null, 8, ["item", "onUpdate"]);
  }), 128))])]);
}