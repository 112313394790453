import hyRequest from '@/service';
import { IStockTrendParams, IStockTrend } from './type';

enum DashboardApi {
  getStockTrend = '/stockdata/historyStoneValue',
  getMonitorAccessToken = '/monitor/getMonitorAccessToken'
}

export function getStockTrend(params: IStockTrendParams) {
  return hyRequest.post<IStockTrend>({
    url: DashboardApi.getStockTrend,
    data: params
  });
}
export function getMonitorAccessToken() {
  return hyRequest.post<string>({
    url: DashboardApi.getMonitorAccessToken
  });
}
