import { IProData } from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getFactorySaleStoneData = '/saleData/factorySaleStoneData'
}

// 物料销售排行
export function getFactorySaleStoneData() {
  return hyRequest.post<IProData>({
    data: '{}',
    url: DashboardApi.getFactorySaleStoneData,
    headers: { 'Content-Type': 'application/json' }
  });
}
