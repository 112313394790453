/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.reflect.delete-property.js";
import useCloseList from './hooks/useCloseList';
import DowntimeDetailItem from './cpns/DowntimeDetailItem.vue';
import localCache from '@/utils/localCache';
export default defineComponent({
  name: 'ShutdownReason',
  components: {
    DowntimeDetailItem: DowntimeDetailItem
  },
  setup: function setup() {
    var route = useRoute();
    var router = useRouter();
    var title = ref('');
    var _useCloseList = useCloseList(),
      _useCloseList2 = _slicedToArray(_useCloseList, 3),
      proCloseListParams = _useCloseList2[0],
      proCloseList = _useCloseList2[1],
      getProCloseListAsync = _useCloseList2[2];
    if (route.params.areaId) {
      localCache.setItem('shutdownReasonParams', JSON.stringify(route.params));
    }
    var shutdownReasonParams = localCache.getItem('shutdownReasonParams');
    if (shutdownReasonParams) {
      var info = JSON.parse(shutdownReasonParams);
      title.value = info.title + '开停机信息';
      Reflect.deleteProperty(info, 'title');
      proCloseListParams.value = info;
    }
    var updateProCloseList = function updateProCloseList() {
      console.log('update .......');
      getProCloseListAsync();
    };
    onMounted(function () {
      getProCloseListAsync();
    });
    var back = function back() {
      router.back();
    };
    return {
      proCloseList: proCloseList,
      back: back,
      updateProCloseList: updateProCloseList,
      title: title
    };
  }
});