import HyRequest from '@/service';

// 数据总览
export function getDeviceInfoList(params: {
  pageIndex: number;
  pageSize: number;
}) {
  return HyRequest.post({
    url: '/deviceEaApp/findDeviceList',
    data: {
      ...params
    }
  });
}
// 获取指派人\提醒人
export function getDeviceUsers(): Promise<any> {
  return HyRequest.get({
    url: '/deviceEaApp/findUsers'
  });
}

// 获取告警规则指派列表
export function getWarningDisposeList(data: {
  pageIndex: number;
  pageSize: number;
}): Promise<any> {
  return HyRequest.post({
    url: `/deviceEAWarnRuleApp/getOtherRuleList`,
    data
  });
}
// 获取告警规则告警列表
export function getWarningRuleList(data: {
  pageIndex: number;
  pageSize: number;
}): Promise<any> {
  return HyRequest.post({
    url: `/deviceEAWarnRuleApp/getRuleList`,
    data
  });
}

// 修改告警规则
export function editWarningRule(id: number, data: unknown): Promise<any> {
  return HyRequest.put({
    url: `/deviceEAWarnRuleApp/edit/${id}`,
    data
  });
}

// 查询告警规则
export function getWarningRule(id: number): Promise<any> {
  return HyRequest.get({
    url: `/deviceEAWarnRuleApp/view/${id}`,
    method: 'GET'
  });
}

// 添加规则
export function postAddWarnRule(params: {
  warnType?: number;
  users?: { name: string; id: number }[];
}): Promise<any> {
  return HyRequest.post({
    url: '/deviceEAWarnRuleApp/add',
    data: {
      ...params
    }
  });
}
// 获取告警列表
export function postWarnRuleList(params: {
  pageIndex: number;
  pageSize: number;
  t?: any;
}): Promise<any> {
  return HyRequest.post({
    url: '/deviceEAWarnApp/findDeviceEAWarns',
    data: {
      ...params
    }
  });
}
// 上传图片
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function uploadImage(data: any, callback?: any) {
  return HyRequest.post({
    // url: `/deviceEAWarnApp/upload`,
    url: `/deviceEAWarnApp/uploadSingleton`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (event) => {
      if (event.lengthComputable) {
        const progressPercent = (event.loaded / event.total) * 100;
        callback && callback(progressPercent);
      }
    }
  });
}
// 删除图片
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function delImage(id: any) {
  return HyRequest.delete({
    url: `/deviceEAWarnApp/delPic/${id}`
  });
}
// 处理
export function postWarnHandle(params: {
  id: string | number;
  result: string;
  attachIds: any;
}): Promise<any> {
  return HyRequest.put({
    // url: `/deviceEAWarnApp/dealWith`,
    url: `/deviceEAWarnApp/dealWithAndAttach`,
    data: {
      result: params.result,
      id: params.id,
      attachIds: params.attachIds
    }
  });
}
// 详情
export function getWarnDetail(id: number): Promise<any> {
  return HyRequest.get({
    url: `/deviceEAWarnApp/get/${id}`
  });
}
