import hyRequest from '@/service';
// import { IDetailListItem } from '../equipmentLive/type';
import {
  IMainData,
  ISandData,
  IChangeShiftDate,
  IClassesUsers,
  IOpenCloseData,
  IProCloseDetail,
  ITimeRange,
  IOpenCloseParams,
  IProCloseDetailParams,
  IUpdateDowntimeReasonParams,
  IProductHourData,
  ITrendParams,
  IDateParams,
  IStoneEle,
  IProDataTrendItem,
  IDeviceAreaListParams,
  IDeviceAreaListItem
} from './type';

enum DashboardApi {
  getChangeShiftDate = '/product/getChangeShiftDate',
  getMainData = '/product/getMainData',
  getSandData = '/product/getSandData',
  getClassesUsers = '/product/getClassesUsers',
  getOpenCloseList = '/product/getOpenCloseList',
  proCloseDetail = '/product/proCloseDetail',
  updateAggregateDetails = '/product/updateAggregateDetailsForLT',
  getProductHourData = '/product/productHourData',
  canUpdateProData = '/sysRoleApp/canUpdateProData',
  getStoneEleByDateType = '/product/getStoneEleByDateType',
  getProDataTrend = '/product/proDataTrend',
  getDeviceAreaList = '/product/getDeviceAreaList'
}

// 获取主机总汇产量数据
export function getMainData(data: ITimeRange) {
  return hyRequest.post<IMainData>({
    url: DashboardApi.getMainData,
    data
  });
}

// 获取制砂总汇产量数据
export function getSandData(data: ITimeRange) {
  return hyRequest.post<ISandData>({
    url: DashboardApi.getSandData,
    data
  });
}

// 查询交接班 可选日期
export function getChangeShiftDate() {
  return hyRequest.post<IChangeShiftDate>({
    url: DashboardApi.getChangeShiftDate
  });
}

// 查询班次人员
export function getClassesUsers() {
  return hyRequest.post<IClassesUsers>({
    url: DashboardApi.getClassesUsers
  });
}

// 查询当天开停机列表
export function getOpenCloseList(data: IOpenCloseParams) {
  return hyRequest.post<IOpenCloseData>({
    url: DashboardApi.getOpenCloseList,
    data
  });
}

// 查询时间范围内停机详情
export function getProCloseDetail(data: IProCloseDetailParams) {
  return hyRequest.post<IProCloseDetail>({
    url: DashboardApi.proCloseDetail,
    data
  });
}

// 骨料日报修改停机原因
export function updateDowntimeReason(data: IUpdateDowntimeReasonParams) {
  return hyRequest.post<string>({
    url: DashboardApi.updateAggregateDetails,
    data
  });
}

//小时产量
export function getProductHourData(data: { dayTime: string }) {
  return hyRequest.post<IProductHourData>({
    url: DashboardApi.getProductHourData,
    data
  });
}
//查看是否有产量修正页面
export function canUpdateProData() {
  return hyRequest.get<'0' | '1'>({
    url: DashboardApi.canUpdateProData
  });
}

//根据时间类型获取骨料产量、耗电量、吨耗电
export function getStoneEleByDateType(data: IDateParams) {
  return hyRequest.post<IStoneEle>({
    url: DashboardApi.getStoneEleByDateType,
    data
  });
}
//产量趋势
export function getProDataTrend(data: ITrendParams) {
  return hyRequest.post<{ list: IProDataTrendItem[] }>({
    url: DashboardApi.getProDataTrend,
    data
  });
}

//查询产线列表
export function getDeviceAreaList(data: IDeviceAreaListParams) {
  return hyRequest.post<{ list: IDeviceAreaListItem[] }>({
    url: DashboardApi.getDeviceAreaList,
    data
  });
}

//获取主机总汇产量数据
//获取制砂总汇产量数据
//查询当天开停机列表
