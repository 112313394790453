import "core-js/modules/es.array.includes.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-208ba7b7"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "natural-day"
};
var _hoisted_2 = {
  class: "hostSummary"
};
var _hoisted_3 = {
  class: "listBox"
};
var _hoisted_4 = {
  class: "title text-ellipsis"
};
var _hoisted_5 = {
  class: "hostSummary"
};
var _hoisted_6 = {
  class: "num"
};
var _hoisted_7 = {
  class: "desc"
};
var _hoisted_8 = {
  class: "desc"
};
var _hoisted_9 = {
  class: "num"
};
var _hoisted_10 = {
  class: "desc"
};
var _hoisted_11 = {
  class: "num"
};
var _hoisted_12 = {
  class: "desc"
};
var _hoisted_13 = {
  key: 0,
  class: "listBox mt-2"
};
var _hoisted_14 = {
  class: "title text-ellipsis"
};
var _hoisted_15 = {
  key: 0,
  class: "listBox mt-2"
};
var _hoisted_16 = {
  class: "title text-ellipsis"
};
var _hoisted_17 = {
  key: 0,
  class: "hostSummary"
};
var _hoisted_18 = {
  class: "num"
};
var _hoisted_19 = {
  class: "desc"
};
var _hoisted_20 = {
  key: 0,
  class: "listBox mt-2"
};
var _hoisted_21 = {
  class: "title text-ellipsis"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_date_header_with_btn = _resolveComponent("c-date-header-with-btn");
  var _component_c_title = _resolveComponent("c-title");
  var _component_yield_sum = _resolveComponent("yield-sum");
  var _component_bar_echart = _resolveComponent("bar-echart");
  var _component_open_close_item = _resolveComponent("open-close-item");
  var _component_c_no_data = _resolveComponent("c-no-data");
  var _component_van_grid_item = _resolveComponent("van-grid-item");
  var _component_van_grid = _resolveComponent("van-grid");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_c_date_header_with_btn, {
    ref: "dateRef",
    type: 0,
    onConfirmDate: _ctx.confrimDate,
    onSwitch: _ctx.switchClick
  }, null, 8, ["onConfirmDate", "onSwitch"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_c_title, {
    title: "主机生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.mainDataTotal.status === '1',
      close: _ctx.mainDataTotal.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: _ctx.hasPermission,
    curDate: _ctx.date.startTime,
    data: _ctx.mainDataTotal
  }, null, 8, ["isJump", "curDate", "data"]), _ctx.hourDataTotal.lineData.length ? (_openBlock(), _createBlock(_component_bar_echart, _normalizeProps(_mergeProps({
    key: 0
  }, _ctx.hourDataTotal)), null, 16)) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.mainBreakingList.name) + "开停机信息 ", 1), _ctx.mainBreakingList.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.mainBreakingList.list, function (item) {
    return _openBlock(), _createBlock(_component_open_close_item, {
      key: item.closeTime,
      item: item,
      title: _ctx.mainBreakingList.name,
      type: "0"
    }, null, 8, ["item", "title"]);
  }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  }))])]), _ctx.factoryID != 165 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_c_title, {
    title: "砂石骨料生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.sandData.status === '1',
      close: _ctx.sandData.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: _ctx.hasPermission,
    curDate: _ctx.date.startTime,
    data: _ctx.sandDataTotal
  }, null, 8, ["isJump", "curDate", "data"])], 2), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 3
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandListData, function (item) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: item.material_name
        }, [_ctx.factoryID != 187 || item.material_name != '收尘粉/吨' ? (_openBlock(), _createBlock(_component_van_grid_item, {
          key: 0
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.emptyStringFormat(item.pro_num)), 1), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.emptyStringFormat(item.material_name)), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.emptyStringFormat(item.ratio)), 1)];
          }),
          _: 2
        }, 1024)) : _createCommentVNode("", true)], 64);
      }), 128))];
    }),
    _: 1
  }), _createVNode(_component_c_title, {
    title: "耗电统计"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandEleTotal, function (item) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: item.desc
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.emptyStringFormat(item.num)), 1), _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.emptyStringFormat(item.desc)), 1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), _ctx.factoryID == 187 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_c_title, {
    title: "泥饼量"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_grid_item, null, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.sandData.soil.net_weight), 1), _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.sandData.soil.material_name), 1)];
        }),
        _: 1
      })];
    }),
    _: 1
  })], 64)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandMakingList, function (item) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: item.name
    }, [_ctx.factoryID == 187 ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [!['制砂线', '水洗线'].includes(item.name) ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(item.name) + "开停机信息", 1), item.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.list, function (iten) {
      return _openBlock(), _createBlock(_component_open_close_item, {
        key: iten.closeTime,
        item: iten,
        title: item.name,
        type: "1"
      }, null, 8, ["item", "title"]);
    }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
      key: 1
    }))])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [!['制砂线', '水洗线'].includes(item.name) ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.name) + "开停机信息", 1), item.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.list, function (iten) {
      return _openBlock(), _createBlock(_component_open_close_item, {
        key: iten.closeTime,
        item: iten,
        title: item.name,
        type: "1"
      }, null, 8, ["item", "title"]);
    }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
      key: 1
    }))])) : _createCommentVNode("", true)], 64))], 64);
  }), 128))]), _ctx.factoryID != 187 ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_c_title, {
    title: "水洗生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.waterData.status === '1',
      close: _ctx.waterData.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: _ctx.hasPermission,
    curDate: _ctx.date.startTime,
    data: _ctx.waterDataTotal
  }, null, 8, ["isJump", "curDate", "data"])], 2), _createVNode(_component_c_title, {
    title: "耗电统计"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.waterTotal, function (item) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: item.desc
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.emptyStringFormat(item.num)), 1), _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.emptyStringFormat(item.desc)), 1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandMakingList, function (item) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: item.name
    }, [item.name == '水洗线' ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(item.name) + "开停机信息", 1), item.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.list, function (iten) {
      return _openBlock(), _createBlock(_component_open_close_item, {
        key: iten.closeTime,
        item: iten,
        title: item.name,
        type: "1"
      }, null, 8, ["item", "title"]);
    }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
      key: 1
    }))])) : _createCommentVNode("", true)], 64);
  }), 128))])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]);
}