import hyRequest from '@/service';
import { IEquipmentDataNew, IEquipmentDataInfoNew } from './type';

// 收藏设备
export function addVrmDeviceCollect(data: {
  factoryId: number;
  deviceId: number;
  vrmWarnValue: number;
  currentWarnValue: number;
}) {
  return hyRequest.post<any>({
    url: '/vibCurrAnalyse/addVrmDeviceCollect',
    data
  });
}
// 编辑设备
export function editVrmDeviceCollect(data: {
  deviceId: number;
  vrmWarnValue: string;
  currentWarnValue: string;
}) {
  return hyRequest.post<any>({
    url: '/vibCurrAnalyse/editVrmDeviceCollect',
    data
  });
}
// 取消收藏设备
export function delVrmDeviceCollect(data: { deviceId: number }) {
  return hyRequest.post<any>({
    url: '/vibCurrAnalyse/delVrmDeviceCollect',
    data
  });
}
// 震动设备
export function getVibrationEquipment(factoryId: number, DEVICE_TYPE = 'Y') {
  return hyRequest.post<IEquipmentDataInfoNew>({
    url: '/vibCurrAnalyse/findVrmDevice',
    data: { DEVICE_TYPE, factoryId }
  });
}
// 震动设备分类
export function findVrmDeviceClassify() {
  return hyRequest.post<IEquipmentDataNew>({
    url: '/vibCurrAnalyse/findVrmDeviceClassify'
  });
}
// 武汉制砂机震动与电流曲线
export function getCurrentCurve(data: {
  date: string;
  type: number;
  deviceId: number | string;
  factoryId: number | string;
}) {
  return hyRequest.post<any>({
    url: '/vibCurrAnalyse/findVrm',
    data
  });
}
// 获取开机，关机时长
export function getOpenCloseTome(data: { date: string; type: number }) {
  return hyRequest.post<any>({
    url: '/vibCurrAnalyse/runTime',
    data
  });
}
