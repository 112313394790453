const isEmpty = (value: undefined & any): boolean => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    JSON.stringify(value)
  ) {
    return true;
  } else return false;
};

export { isEmpty };
