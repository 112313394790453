/* unplugin-vue-components disabled */import { useStore } from '@/store';
export default defineComponent({
  name: 'Usings',
  setup: function setup() {
    var store = useStore();
    var useList = computed(function () {
      return store.state.production.useList;
    });
    var itemClick = function itemClick(value) {
      window.open(value.link, '__bank');
    };
    return {
      useList: useList,
      itemClick: itemClick
    };
  }
});