import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/equipmentLive/open.png';
import _imports_1 from '@/assets/img/equipmentLive/close.png';
import _imports_2 from '@/assets/img/equipmentLive/star-active.png';
import _imports_3 from '@/assets/img/equipmentLive/star.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-33da5f49"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "flex align-center mb-2"
};
var _hoisted_2 = {
  key: 0,
  src: _imports_0
};
var _hoisted_3 = {
  key: 1,
  src: _imports_1
};
var _hoisted_4 = {
  class: "ml-2 title"
};
var _hoisted_5 = {
  class: "flex align-center justify-between"
};
var _hoisted_6 = {
  class: "flex flex-wrap info"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  class: "avatar"
};
var _hoisted_10 = ["src"];
var _hoisted_11 = {
  key: 0,
  src: _imports_2
};
var _hoisted_12 = {
  key: 1,
  src: _imports_3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["list-item", {
      active: _ctx.item.device_status === '1'
    }]),
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.toDetail && _ctx.toDetail.apply(_ctx, arguments);
    })
  }, [_createElementVNode("div", _hoisted_1, [_ctx.item.device_status === '1' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3)), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.device), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.recordList, function (iten) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: iten.name
    }, [iten.name == '电流：' && _ctx.item.device_status === '0' ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(iten.name) + "0A", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(iten.name) + _toDisplayString(iten.value), 1))], 64);
  }), 128))]), _createElementVNode("div", _hoisted_9, [_createElementVNode("img", {
    src: _ctx.item.device_logo ? _ctx.item.device_logo : '~@/asstes/img/equipmentLive/device_logo.png'
  }, null, 8, _hoisted_10)])]), _createElementVNode("div", {
    class: "star",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    function () {
      return _ctx.updateFocusAsync && _ctx.updateFocusAsync.apply(_ctx, arguments);
    }, ["stop"]))
  }, [_ctx.item.focus === '1' ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : (_openBlock(), _createElementBlock("img", _hoisted_12))])], 2);
}