import hyRequest from '@/service';
import {
  IDeviceGroup,
  IUpdateFocusParams,
  IUpdateFocus,
  IDetailParams,
  IDetailData,
  IDetailNewData,
  IDetailTemperData,
  IThreeTemperParams,
  IThreeTemperData,
  IOpenCloseData,
  IFacParams,
  IFacData,
  IDeviceGroupDataNew
} from './type';

enum DashboardApi {
  getDeviceGroup = '/deviceapp/deviceGroup',
  getDeviceGroupNew = '/deviceapp/deviceGroupNew',
  updateFocus = '/deviceapp/updateFocus',
  getDeviceInfo = '/deviceapp/deviceInfo',
  getNewClassReal = '/deviceapp/newClassReal',
  getDeviceTemper = '/deviceapp/deviceTemper',
  getThreeTemper = '/deviceapp/threeTemper',
  getOpenCloseMechineMore = '/deviceapp/openCloseMechineMore',
  getFac = '/deviceapp/deviceGroupDetail',
  getDeviceGroupMyFocusDetail = '/deviceapp/deviceGroupMyFocusDetail'
}

// 设备分组，关注的设备及类型
export function getDeviceGroup() {
  return hyRequest.post<IDeviceGroup>({
    url: DashboardApi.getDeviceGroup
  });
}

// 设备分组，关注的设备及类型 新接口
export function getDeviceGroupNew() {
  return hyRequest.post<IDeviceGroupDataNew>({
    url: DashboardApi.getDeviceGroupNew
  });
}

export function getFac(data: IFacParams) {
  return hyRequest.post<IFacData>({
    url: DashboardApi.getFac,
    data
  });
}

export function getDeviceGroupMyFocusDetail() {
  return hyRequest.post<IFacData>({
    url: DashboardApi.getDeviceGroupMyFocusDetail
  });
}

//更新设备关注状态
export function updateFocus(data: IUpdateFocusParams) {
  return hyRequest.post<IUpdateFocus>({
    url: DashboardApi.updateFocus,
    data
  });
}

//设备实况固定信息
export function getDeviceInfo(data: IDetailParams) {
  return hyRequest.post<IDetailData>({
    url: DashboardApi.getDeviceInfo,
    data
  });
}
//新设备实况
export function getNewClassReal(data: IDetailParams) {
  return hyRequest.post<IDetailNewData>({
    url: DashboardApi.getNewClassReal,
    data
  });
}
//查询设备下所有电机，电机下所有变量
export function getDeviceTemper(data: IDetailParams) {
  return hyRequest.post<IDetailTemperData>({
    url: DashboardApi.getDeviceTemper,
    data
  });
}
//电流趋势
export function getThreeTemper(data: IThreeTemperParams) {
  return hyRequest.post<IThreeTemperData>({
    url: DashboardApi.getThreeTemper,
    data
  });
}
//查询最近3天开关机时间段
export function getOpenCloseMechineMore(data: IDetailParams) {
  return hyRequest.post<IOpenCloseData>({
    url: DashboardApi.getOpenCloseMechineMore,
    data
  });
}
