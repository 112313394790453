import hyRequest from '@/service';
import {
  IAttendance,
  IAttendanceInfo,
  IAttendanceParams,
  IAttendanceMonthInfo,
  IAbsentUser
} from '@/service/work/attendanceAnalysis/type';

enum DashboardApi {
  getAttendanceStatistics = '/oa/attendanceStatistics',
  getDepAttendanceInfo = '/oa/depAttendanceInfo',
  getDepAttendanceMonthInfo = '/oa/depAttendanceMonthInfo',
  getAbsentUserList = '/oa/absentUserList'
}

// 获取实时考勤
export function getAttendanceStatistics(params: IAttendanceParams) {
  return hyRequest.post<IAttendance>({
    url: DashboardApi.getAttendanceStatistics,
    data: params
  });
}
//本日各部门出勤情况
export function getDepAttendanceInfo(params: IAttendanceParams) {
  return hyRequest.post<IAttendanceInfo>({
    url: DashboardApi.getDepAttendanceInfo,
    data: params
  });
}

//部门考勤月度分析
export function getDepAttendanceMonthInfo(params: IAttendanceParams) {
  return hyRequest.post<IAttendanceMonthInfo>({
    url: DashboardApi.getDepAttendanceMonthInfo,
    data: params
  });
}
//未出勤人员信息
export function getAbsentUserList(params: IAttendanceParams) {
  return hyRequest.post<IAbsentUser>({
    url: DashboardApi.getAbsentUserList,
    data: params
  });
}
