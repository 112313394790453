/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.push.js";
import useGroupList from './hooks/useGoupListNew';
import { useStore } from '@/store';
export default defineComponent({
  name: 'VibrationEquipment',
  components: {},
  setup: function setup() {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var _useGroupList = useGroupList(),
      _useGroupList2 = _slicedToArray(_useGroupList, 3),
      siderbar = _useGroupList2[0],
      loading = _useGroupList2[1],
      getDeviceGroupAsync = _useGroupList2[2];
    onMounted(function () {
      // 获取左侧菜单列表
      getDeviceGroupAsync();
    });
    var curSiderbarIndex = computed(function () {
      return store.state.vibrationEquipment.curSiderbarIndex;
    });
    var siderbarClick = function siderbarClick(index, id) {
      store.commit('vibrationEquipment/updateSiderbarIndex', index);
      store.commit('vibrationEquipment/updateSiderbarId', id);
      if (route.fullPath === '/vibrationEquipment/list') {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        return;
      }
      router.push({
        name: 'VibrationEquipment'
      });
    };
    return {
      siderbar: siderbar,
      curSiderbarIndex: curSiderbarIndex,
      siderbarClick: siderbarClick,
      loading: loading
    };
  }
});