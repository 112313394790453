import { IStock } from './type';
import hyRequest, { hyRequest1 } from '@/service';

enum DashboardApi {
  getTotalData = '/report/report/spareCount',
  getRank = '/stockdata/stockStatus'
}

// 料位料价
export function getStockData() {
  return hyRequest.get<{ list: IStock[] }>({
    url: DashboardApi.getRank
  });
}

export function deviceWeightRatio() {
  return hyRequest.get<any>({
    url: '/stockdata/deviceWeightRatio'
  });
}

export function saveWeightRatio(data: unknown) {
  return hyRequest.post<any>({
    url: '/stockdata/saveWeightRatio',
    data
  });
}
//上面都是骨料数据请求
//商砼数据
export function getStockData1() {
  return hyRequest1.get<any>({
    url: '/report/report/stoneRealtimeStockOfConcrete'
  });
}

//砂浆数据
export function getStockData2() {
  return hyRequest1.get<any>({
    url: '/report/report/stoneRealtimeStockOfMortar'
  });
}
